class PlanUtils {
  static egpPrice = (plan) => {
    return `EGP ${plan.egpPrice}`;
  };

  static usdPrice = (plan) => {
    return `USD ${plan.usdPrice}`;
  };

  static getPlanPrice = ({
    discountOnEgpPrice,
    egpDiscountPrice,
    egpPrice,
    discountOnUsdPrice,
    usdDiscountPrice,
    usdPrice,
  }) => {
    const geolocation = JSON.parse(localStorage.getItem("geolocation"));
    if (discountOnEgpPrice.active && geolocation?.country_code === "EG") {
      return {
        price: egpDiscountPrice,
        oldPrice: egpPrice,
        currency: "EGP",
      };
    } else if (discountOnUsdPrice.active && geolocation?.country_code !== "EG") {
      return {
        price: usdDiscountPrice,
        oldPrice: usdPrice,
        currency: "USD",
      };
    } else {
      return geolocation?.country_code === "EG"
        ? { price: egpPrice, currency: "EGP" }
        : { price: usdPrice, currency: "USD" };
    }
  };

  static clearCart = () => {
    localStorage.removeItem("cart");
  };

  static getColor = (plan, target) => {
    if (target === "bg") {
      switch (plan.frequency) {
        case "three_day":
          return `bg-amber-300`;
        case "weekly":
          return `bg-red-500`;
        case "monthly":
          return `bg-rose-500`;
        case "three_month":
          return `bg-blue-500`;
        case "yearly":
          return `bg-green-500`;
        default:
          return `bg-amber-300`;
      }
    } else if (target === "text") {
      switch (plan.frequency) {
        case "three_day":
          return `text-amber-300`;
        case "weekly":
          return `text-red-500`;
        case "monthly":
          return `text-rose-500`;
        case "three_month":
          return `text-blue-500`;
        case "yearly":
          return `text-green-500`;
        default:
          return `text-amber-300`;
      }
    } else if (target === "border") {
      const geolocation = JSON.parse(localStorage.getItem("geolocation"));
      if (
        (geolocation.country_code === "EG" && plan.discountOnEgpPrice.active) ||
        (geolocation.country_code !== "EG" && plan.discountOnUsdPrice.active)
      ) {
        switch (plan.frequency) {
          case "three_day":
            return `border-solid border-2 border-amber-300 shadow-2xl shadow-amber-300`;
          case "weekly":
            return `border-solid border-2 border-red-500 shadow-2xl shadow-red-500`;
          case "monthly":
            return `border-solid border-2 border-rose-500 shadow-2xl shadow-rose-500`;
          case "three_month":
            return `border-solid border-2 border-blue-500 shadow-2xl shadow-blue-500`;
          case "yearly":
            return `border-solid border-2 border-green-500 shadow-2xl shadow-green-500`;
          default:
            return `border-solid border-2 border-amber-300 shadow-2xl shadow-amber-300`;
        }
      }
    }
  };
}

export default PlanUtils;
