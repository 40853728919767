const requests = {
  announcements: `/announcements`,
  home: `/home`,
  fetchBooks: `books`,
  comments: `comments`,
  replies: `replies`,
  categories: `categories`,
  authors: `authors`,
  serieses: "serieses",
  login: `users/login`,
  myAccount: `users/my-account`,
  register: `users/register`,
  verify: `users/verify`,
  forgetPassword: `users/forget-password`,
  verifyForgetPasswordCode: `users/verify-forget-password-code`,
  resetPassword: `users/reset-password`,
  userLogs: `users/logs`,
  userFavorites: `users/favorites`,
  toggleFavorite: `users/toggle-favorite`,
  notifications: `users/notifications`,
  delete: "users/delete",
  fetchStatistics: `statistics`,
  fetchPlans: `plans`,
  upload: `upload`,
  payments: `payments`,
  settings: `settings`,
  ok: "SUCCESS",
  admin: {
    books: `admin/books`,
    authors: `admin/authors`,
    categories: `admin/categories`,
    comments: `admin/comments`,
    replies: `admin/replies`,
    users: `admin/users`,
    support: `admin/support`,
    announcements: `admin/announcements`,
    serieses: `admin/serieses`,
    plans: `admin/plans`,
    transactions: `admin/transactions`,
    statistics: `admin/statistics`,
    notifications: `admin/notifications`,
    settings: `admin/settings`,
  },
};
export default requests;
