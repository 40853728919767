import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import { PlanUtils } from "../../utils";
//------------------------------------------------------------------------------//
//------------------------------------------------------------------------------//
const Card = ({ plan, showSubscribeButton, user, cart, setAppAlertDialog }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const geolocation = JSON.parse(localStorage.getItem("geolocation"));
  //------------------------------------------------------------------------------//
  //------------------------------------------------------------------------------//
  return (
    <Box className="flex flex-col items-center max-[720px]:m-8">
      {/* --------------------------------------offer header-------------------------------------- */}
      {/* ----------------------------------------offer header------------------------------------ */}
      {geolocation.country_code === "EG" && plan.discountOnEgpPrice.active && (
        <Box
          className={`h-6 w-36 mb-0 text-white text-center rounded-t-md 
                    ${PlanUtils.getColor(plan, "bg")}`}
        >
          {t(`plan.discount`)}
        </Box>
      )}
      {geolocation.country_code !== "EG" && plan.discountOnUsdPrice.active && (
        <Box
          className={`h-6 w-36 mb-0 text-white text-center rounded-t-md 
                    ${PlanUtils.getColor(plan, "bg")}`}
        >
          {t(`plan.discount`)}
        </Box>
      )}
      {/* --------------------------------------------card---------------------------------------- */}
      {/* --------------------------------------------card---------------------------------------- */}
      <Box
        className={`flex flex-col justify-around items-center bg-neutral-950 rounded-3xl w-48 ${PlanUtils.getColor(
          plan,
          "border"
        )} ${user?.plan?.title === plan.title ? "change-plan-selected" : ""}`}
        key={plan._id}
      >
        {/* -------------------------------------------title---------------------------------------- */}
        {/* -------------------------------------------title---------------------------------------- */}
        <Typography component="h1" align="center" className="text-white my-1 text-3xl">
          {plan.title}
        </Typography>
        {/* ---------------------------------------colored box-------------------------------------- */}
        {/* ---------------------------------------colored box-------------------------------------- */}
        <Box
          className={`flex flex-col items-center justify-around rounded-3xl w-44 h-48 relative top-4 ${PlanUtils.getColor(
            plan,
            "bg"
          )} `}
        >
          {/* -----------------current price-------------------- */}
          {/* -------------------------------------------------- */}
          <Box className="text-white flex flex-row items-start">
            <Typography component="span" className="text-5xl mx-2 align-text-top leading-none">
              {PlanUtils.getPlanPrice(plan).price}
            </Typography>
            <Typography component="span" className="text-lg align-text-top leading-none">
              {t(`plan.currency.${PlanUtils.getPlanPrice(plan).currency}`).split(" ")[0]}
              <br></br>
              {t(`plan.currency.${PlanUtils.getPlanPrice(plan).currency}`).split(" ")[1]}
            </Typography>
          </Box>
          {/* -----------------old price-------------------- */}
          {/* ---------------------------------------------- */}
          {((geolocation.country_code === "EG" && plan.discountOnEgpPrice.active) ||
            (geolocation.country_code !== "EG" && plan.discountOnUsdPrice.active)) && (
            <Box className="text-white flex flex-row items-start">
              <Typography
                component="span"
                className="text-5xl mx-2 align-text-top leading-none line-through decoration-black decoration-2"
              >
                {PlanUtils.getPlanPrice(plan).oldPrice}
              </Typography>
              <Typography
                component="span"
                className="text-lg align-text-top leading-none line-through decoration-black decoration-1"
              >
                {t(`plan.currency.${PlanUtils.getPlanPrice(plan).currency}`).split(" ")[0]}
                <br></br>
                {t(`plan.currency.${PlanUtils.getPlanPrice(plan).currency}`).split(" ")[1]}
              </Typography>
            </Box>
          )}
          {/* -----------------frequency-------------------- */}
          {/* ---------------------------------------------- */}
          <Typography
            component="h1"
            align="center"
            className="bg-white/50 text-xl text-white w-full"
          >
            {t(`plan.frequency.${plan.frequency}`)}
          </Typography>
          {/* ---------------subscribe button--------------- */}
          {/* ---------------------------------------------- */}
          {showSubscribeButton && (
            <Box>
              <button
                className={`rounded-xl bg-white w-28 ${PlanUtils.getColor(plan, "text")}`}
                onClick={() => {
                  if (!user) {
                    setAppAlertDialog(true);
                    return;
                  }
                  cart.addItem({
                    ...plan,
                    color: `${PlanUtils.getColor(plan, "bg")}`,
                  });

                  navigate("/checkout");
                }}
              >
                {t(`subscription_benefits_short.subscribe_button`)}
              </button>
            </Box>
          )}
          {/* ---------------------------------------------- */}
          {/* ---------------------------------------------- */}
        </Box>
        {/* ------------------------------------------- end ---------------------------------------- */}
        {/* ------------------------------------------- end ---------------------------------------- */}
      </Box>
    </Box>
  );
};
export default Card;
